/*.App {*/
/*  width: 100vw;*/
/*  background-color: red;*/
/*  box-sizing: border-box;*/
/*}*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 10vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*  width: 100%;*/
/*}*/

/*.app-body {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  justify-content: flex-start;*/
/*  align-items: center;*/
/*  background-color: #606a7b;*/
/*  min-height: 90vh;*/
/*}*/

/*.calender-week {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  justify-content: flex-start;*/
/*  align-items: center;*/
/*  font-size: 1.8rem;*/
/*  margin-top: 3rem;*/
/*}*/

/*.calender-week p {*/
/*  margin: 0;*/
/*}*/

/*.calender-week #curr-cw {*/
/*  font-size: 1rem;*/
/*  margin: 0.8rem 0 3rem 0;*/
/*}*/

/*.cleaning-table {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  justify-content: flex-start;*/
/*  align-items: flex-start;*/
/*  padding: 1rem 1rem;*/
/*}*/

/*.cleaning-table-row {*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  justify-content: flex-start;*/
/*  align-items: flex-start;*/
/*  height: 3rem;*/
/*}*/

/*.cleaning-table-row > p:first-child {*/
/*  width: 7rem;*/
/*  font-weight: bolder;*/
/*}*/

/*.cleaning-table-row p {*/
/*  padding: 0 1rem;*/
/*}*/

/*.floor-div {*/
/*  border-left: 1px solid white;*/
/*  margin: 1rem;*/
/*}*/

/*.button-div {*/
/*  border: none;*/
/*  margin: 4rem 0;*/
/*  padding: 0.1rem 1rem;*/
/*  text-align: center;*/
/*  text-decoration: none;*/
/*  display: inline-block;*/
/*  font-size: 16px;*/
/*  border-radius: 8px;*/
/*  transition-duration: 0.4s;*/
/*}*/

/*.cleaning-person {*/
/*  margin-right: 3rem;*/
/*}*/

/*.button-enabled {*/
/*  cursor: pointer;*/
/*  background-color: #4CAF50; !* Green *!*/
/*  color: white;*/
/*}*/

/*.button-enabled:hover {*/
/*  background-color: #429946; !* Green *!*/
/*}*/

/*.button-disabled {*/
/*  background-color: #a9a9a9;*/
/*  color: black;*/
/*  cursor: not-allowed;*/
/*}*/

@media screen and (min-width: 900px) {
  article {
    padding: 1rem 3rem;
  }
}