body {
    margin-bottom: 7rem;
}

.homeBody {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
