table {
    width: 100vw;
}

th, td {
    padding: 0.8rem 1rem;
}

th {
    text-align: left;
}

td {
    max-width: 30%;
}

p.capitalize {
    text-transform: capitalize;
}

p.not-responsible {
    color: #c1c1c1;
    text-decoration: line-through;
}

.availability-cell {
    padding: 0 1rem;
    width: 20px;
}

td.availability-cell {
    text-align: center;
}

tr:nth-child(even) {background-color: #f2f2f2;}

th:first-child,
td:first-child {
    width: 40%;
    /*min-width: 8em;*/
    max-width: 10em;
    word-break: break-all;
}

.crossed-out  {
    /*text-decoration: line-through;*/
    /*transition: 0.5s ease-in-out;  !*transition: all 1s ease-in-out 0s;*!*/
    /*color: #b1b1b1;*/
}

.not-crossed-out {
    text-decoration: none;
    transition: 0.5s ease-in-out;
}

.button-clicked {
    color: #4caf50;
    background-color: #4CAF50;
}

.modal button {
    font-size: 2rem;
    /*background-color: #4CAF50;*/
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
    opacity: 0.9;
}

.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: #474e5d;
    padding-top: 50px;
    /*opacity: 0.5;*/
}

.modal-content {
    background-color: #fefefe;
    margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
}

.close {
    position: absolute;
    right: 35px;
    top: 15px;
    font-size: 40px;
    font-weight: bold;
    color: #f1f1f1;
}

.cancelbtn {
    background-color: #f44336;
    color: black;
}

.confirmbtn {
    background-color: #4CAF50;
}

.cancelbtn, .confirmbtn {
    float: left;
    width: 50%;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.container {
    padding: 16px;
    text-align: center;
}

@media (min-width: 960px) {
    table {
        width: 650px;
    }
}