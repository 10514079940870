#cleaning-plan-logo {
    position: relative;
    height: 100%;
    width: auto;
}

#user-icon {
    position: relative;
    height: 70%;
    width: auto;
}

.cpHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 1rem 1rem;
    height: 3rem;
}

.cpHeader a {
    position: relative;
    height: 100%;
}